import { Rating } from "@mui/material";
import cn from "classnames";
import Loader from "components/ui/loader/loader";
import { useModalAction } from "provider/modal.context";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSellerReviewsList } from "service/hook/customer";
import {
  BusinessProfileResponseModel,
  SellerReview,
  SellerReviewsListRequest,
  SellerReviewsResponse,
} from "types/business-profile-models";
import moment from "moment";

interface IProps {
  businessProfileData: BusinessProfileResponseModel;
}
const BusinessReviewsView: FC<IProps> = ({ businessProfileData }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { openModal } = useModalAction();

  const [reviews, setReviewsList] = useState<SellerReview[]>([]);

  const { mutate: mutateSellerReviews, isLoading } = useSellerReviewsList();

  const getSellerReviews = () => {
    const input: SellerReviewsListRequest = {
      businessdetail_id: `${businessProfileData.businessdetail_id}`,
      marketplaceId: "GBR",
    };

    mutateSellerReviews(input, {
      onSuccess: (successData: SellerReviewsResponse) => {
        if (successData.final_result_code === "000") {
          setReviewsList(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getSellerReviews();
  }, []);

  return (
    <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14">
      <div className="flex h-full flex-col w-full">
        <div className="flex justify-between items-center">
          <h3 className="py-5 px-5 text-xl font-semibold text-heading">
            {t("text-reviews")}
          </h3>
          <div className="flex gap-4">
            <button
              onClick={() => {
                openModal("CUSTOMER_REVIEW", {
                  item: {
                    businessDetailId: businessProfileData.businessdetail_id,
                    businessName: businessProfileData.businessdetail_name,
                  },
                  callback: () => {},
                });
              }}
              data-variant="normal"
              className="inline-flex !text-light items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-0 focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent border border-transparent hover:bg-accent-hover px-5 py-0 h-12"
            >
              {t("text-write-review")}
            </button>
          </div>
        </div>
        <div></div>
        {isLoading ? (
          <Loader className="!h-full flex-1" text={t("common:text-loading")} />
        ) : (
          <>
            {reviews.length === 0 && (
              <div className="w-full px-4 py-4 bg-amber-200 rounded !text-lg">
                Messages not found
              </div>
            )}
            {reviews.map((review: SellerReview, index: number) => (
              <div
                key={index}
                className={cn(
                  "mb-4 flex w-full shrink-0 cursor-pointer flex-col overflow-hidden rounded border-2 border-transparent bg-gray-100 last:mb-0"
                )}
              >
                <div className="flex flex-col p-5 md:p-3 lg:px-4 lg:py-5">
                  <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                    <span className="shrink-0 overflow-hidden font-bold">
                      {t("text-user")} :
                    </span>
                    <span className="ltr:ml-1 rtl:mr-1">
                      {review.customer_id}
                    </span>
                  </p>
                  <div className="flex gap-4 mb-2">
                    <span className="shrink-0 overflow-hidden font-bold text-sm">
                      {t("text-rate")} :
                    </span>
                    <Rating
                      name="review-rate"
                      value={review.seller_score}
                      readOnly
                      precision={0.25}
                    />
                  </div>
                  <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                    <span className="shrink-0 overflow-hidden font-bold">
                      {t("text-title")} :
                    </span>
                    <span className="ltr:ml-1 rtl:mr-1">
                      {review.review_title}
                    </span>
                  </p>
                  <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                    <span className="shrink-0 overflow-hidden font-bold">
                      {t("text-date")} :
                    </span>
                    <span className="ltr:ml-1 rtl:mr-1">
                      {moment(review.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  </p>
                  <p className="mb-2 flex w-full items-center text-sm text-heading last:mb-0 gap-4">
                    <span className="shrink-0 overflow-hidden font-bold">
                      {t("text-review")} :
                    </span>
                    <span className="ltr:ml-1 rtl:mr-1">
                      {review.review_content}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessReviewsView;
