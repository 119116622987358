// import { useLogout, useResendVerificationEmail, useUser } from "@/framework/user";
// import Button from "@/components/ui/button";
// import Card from "@/components/ui/cards/card";
import { useTranslation } from "react-i18next";
import Card from "../../components/ui/cards/card";
import { Logo } from "../../components";
import Button from "../../components/ui/button";
import { Form } from "../../components/ui/forms/form";
import * as yup from "yup";
import Input from "../../components/ui/forms/input";
import {
  UserShowResponse,
  VerifyEmailInput,
  VerifyEmailResponse,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../store";
import { useUser, useVerifyEmail } from "../../service/hook/user";
import { Routes } from "../../config/routes";
// import Logo from "@/components/ui/logo";
// import { useToken } from "@/lib/hooks/use-token";
// import { useRouter } from "next/router";
// import Link from "@/components/ui/link";
// import { Routes } from "@/config/routes";

// export { getStaticProps } from "@/framework/general.ssr";

const registerFormSchema = yup.object().shape({
  password_verification_code: yup.string().required("error-confirm-required"),
});

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const {
    accessToken,
    setAccessToken,
    email: registerUserEmail,
    setEmail: setEmailStorage,
    setAuthorized,
    isAuthorized,
  } = authStore((state) => state);

  const {
    mutate: getUser,
    isLoading: userIsLoading,
    serverError: userServerError,
    setServerError: usrSetServerError,
  } = useUser();

  // const { mutate: logout, isLoading: isLogoutLoader } = useLogout();
  // useUser();

  // if (emailVerified) {
  //     router.push("/profile");
  // }
  // const handleLogout = () => {
  //     logout();
  //     router.push(Routes.home);
  // };

  const { mutate: verifyEmailMutate, isLoading: isVerifyLoding } =
    useVerifyEmail();

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    setAuthorized(false);
    setAccessToken("");
  };

  function onSubmit({ password_verification_code }: VerifyEmailInput) {
    const request: VerifyEmailInput = {
      email: registerUserEmail,
      password_verification_code,
    };

    verifyEmailMutate(request, {
      onSuccess: async (successData: VerifyEmailResponse) => {
        if (isAuthorized && accessToken) {
          getUser({ email: registerUserEmail });
        }

        if (successData.final_result_code === "000") {
          navigate(Routes.home);
        }
      },
    });
  }

  return (
    <section className="relative flex min-h-screen w-full items-center justify-center bg-[#F4F6F7] py-5 px-4 md:py-8">
      <div className="max-w-[36rem]">
        <Card className="text-center !shadow-900 md:px-[4.375rem] md:py-[2.875rem]">
          <div>
            <Logo />
          </div>

          <h2 className="mb-5 mt-2 text-2xl font-semibold">
            {t("common:email-not-verified")}
          </h2>

          <p className="mb-16 text-lg text-[#969FAF]">
            {t("email-not-description")}
          </p>

          <div className="space-y-3">
            <Form<VerifyEmailInput>
              onSubmit={onSubmit}
              validationSchema={registerFormSchema}
              serverError={null}
            >
              {({ register, formState: { errors } }) => (
                <>
                  <Input
                    label={t("text-verification-code")}
                    {...register("password_verification_code")}
                    variant="outline"
                    className="mb-5"
                    error={t(errors.password_verification_code?.message!)}
                  />
                  <div className="mt-8">
                    <Button
                      className="h-12 w-full"
                      disabled={isVerifyLoding}
                      loading={isVerifyLoding}
                    >
                      {t("text-confirm")}
                    </Button>
                  </div>
                </>
              )}
            </Form>

            {/* <Button
                            // onClick={() => verifyEmail()}
                            // disabled={isVerifying || !!isLogoutLoader}
                            // loading={isVerifying}
                            className="!h-13 w-full hover:bg-accent-hover"
                        >
                            {t("resend-verification-button-text")}
                        </Button> */}
            <Button
              type="button"
              variant="outline"
              className="!h-13 w-full"
              onClick={() => handleLogout()}
              // disabled={isVerifyLoding}
              // loading={isVerifyLoding}
            >
              {t("auth-menu-logout")}
            </Button>
          </div>
          {/* <div className="mt-4">
                        <Link
                            href={Routes.home}
                            className="inline-flex items-center text-bolder underline hover:text-body-dark hover:no-underline focus:outline-none sm:text-base"
                        >
                        </Link>
                        {t("404-back-home")}
                    </div> */}
        </Card>
      </div>
    </section>
  );
};
VerifyEmail.authenticationRequired = true;
export default VerifyEmail;
