import { useTranslation } from "react-i18next";
import Button from "../../ui/button";
import { useModalAction } from "../../../provider/modal.context";

export default function JoinButton() {
  const { t } = useTranslation("common");
  const { openModal } = useModalAction();
  function handleJoin() {
    return openModal("LOGIN_VIEW");
    // return openModal("FORGOT_VIEW");
  }
  return (
    <Button className="font-semibold" size="small" onClick={handleJoin}>
      {t("text-login")}
    </Button>
  );
}
