import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useModalAction } from "../../provider/modal.context";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import client from "../client";
import { useAtom } from "jotai";
import { authorizationAtom } from "../../store/authorization-atom";
import { useToken } from "../../utils/hooks/use-token";
import { API_ENDPOINTS } from "../client/api-endpoints";
import axios from "axios";
import { userInfoAtom } from "../../store/user-atom";
import { authStore, userStore } from "../../store";
import { RegisterUserInput, UserShowResponse } from "../../types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import {
  initialGlobalState,
  updateFormState,
} from "../../components/auth/forgot-password";

export function useLogin() {
  const { closeModal } = useModalAction();
  // const { setToken } = useToken();
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      if (!data.token) {
        setServerError("error-credential-wrong");
        return;
      }
      // setToken(data.token);
      // closeModal();
    },
    onError: (error: Error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data.non_field_errors) {
        setServerError(data.non_field_errors);
      } else if (data) {
        // toast.error(`${data?.email}`);
        setServerError(error.message);
      }
    },
  });

  return { mutate, isLoading, serverError, setServerError };
}

export function useUser() {
  const { setUser } = userStore((state) => state);
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(client.users.me_v2, {
    onSuccess: (data: UserShowResponse) => {
      setUser({
        firstName: data.captured_data.first_name,
        lastName: data.captured_data.last_name,
        email: data.captured_data.email,
        isConfirmEmail: data.captured_data.email_confirmed,
      });
    },
    onError: (error: Error) => {
      setServerError(error.message);
    },
  });

  //TODO: do some improvement here
  return { mutate, isLoading, serverError, setServerError };
}

export function useUserUpdateMarketPlace() {
  const { setUser } = userStore((state) => state);
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.users.userMarketPlaceUpdate,
    {
      onSuccess: (data: UserShowResponse) => {
        setUser({
          firstName: data.captured_data.first_name,
          lastName: data.captured_data.last_name,
          email: data.captured_data.email,
          isConfirmEmail: data.captured_data.email_confirmed,
        });
      },
      onError: (error: Error) => {
        setServerError(error.message);
      },
    }
  );

  //TODO: do some improvement here
  return { mutate, isLoading, serverError, setServerError };
}

export function useUserMarketPlaceStatus() {
  const { setUser } = userStore((state) => state);
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.users.userMarketPlaceStatus,
    {
      onSuccess: (data: UserShowResponse) => {
        setUser({
          firstName: data.captured_data.first_name,
          lastName: data.captured_data.last_name,
          email: data.captured_data.email,
          isConfirmEmail: data.captured_data.email_confirmed,
        });
      },
      onError: (error: Error) => {
        setServerError(error.message);
      },
    }
  );

  //TODO: do some improvement here
  return { mutate, isLoading, serverError, setServerError };
}

export function useRegister() {
  const { t } = useTranslation("common");
  const { closeModal } = useModalAction();
  const { setEmail, setAuthorized } = authStore((state) => state);
  const { setUser } = userStore((state) => state);
  let [formError, setFormError] = useState<
    Partial<RegisterUserInput> | string | null
  >(null);

  const { mutate, isLoading } = useMutation(client.users.register, {
    onSuccess: (data) => {
      if (
        data.final_result_code === "000" &&
        data.general_result === "user created"
      ) {
        if (!data.captured_data.email_confirmed) {
          setAuthorized(true);
          setEmail(data.captured_data.email);
          setUser({
            email: data.captured_data.email,
            isConfirmEmail: data.captured_data.email_confirmed,
          });

          toast.success(`Please confirm your mail`);
        } else {
          closeModal();
        }
      }
    },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data) {
        if (data?.email) {
          toast.error(`${data?.email}`);
        }
      }

      setFormError(data);
    },
  });

  return { mutate, isLoading, formError, setFormError };
}

export function useVerifyEmail() {
  const { t } = useTranslation("common");
  const { closeModal } = useModalAction();
  const { setEmail, setAuthorized } = authStore((state) => state);
  const { setUser } = userStore((state) => state);
  let [formError, setFormError] = useState<
    Partial<RegisterUserInput> | string | null
  >(null);

  const { mutate, isLoading } = useMutation(client.users.verifyEmail, {
    onSuccess: (data) => {
      if (
        data.final_result_code === "000" &&
        data.general_result === "user created"
      ) {
      }
    },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};

      if (data) {
        if (data?.final_result_code === "001") {
          toast.error(`${data?.general_result}`);
        }
      }

      setFormError(data);
    },
  });

  return { mutate, isLoading, formError, setFormError };
}

export function useForgotPassword() {
  const { actions } = useStateMachine({ updateFormState });
  let [message, setMessage] = useState<string | null>(null);
  let [formError, setFormError] = useState<any>(null);
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(client.users.forgotPassword, {
    onSuccess: (data, variables) => {
      if (data.final_result_code !== "000") {
        setFormError({
          email: data?.general_result ?? "",
        });
        return;
      }
      setMessage(data?.general_result!);

      actions.updateFormState({
        email: variables.email,
        step: "Token",
      });
    },
    onError: (error: Error) => {
      const { response }: any = error ?? {};

      if (response?.data?.general_result) {
        toast.error(`${response?.data?.general_result}`);
      } else {
        setFormError(error.message);
      }
    },
  });

  return { mutate, isLoading, message, formError, setFormError, setMessage };
}

export function useResetPassword() {
  const queryClient = useQueryClient();
  const { actions } = useStateMachine({ updateFormState });

  return useMutation(client.users.resetPassword, {
    onSuccess: (data) => {
      if (data?.final_result_code === "000") {
        toast.success("Successfully Reset Password!");
        actions.updateFormState({
          ...initialGlobalState,
        });
        return;
      }
    },
    onSettled: () => {
      queryClient.clear();
    },
  });
}

export function useVerifyForgotPasswordToken() {
  const { actions } = useStateMachine({ updateFormState });
  const queryClient = useQueryClient();
  let [formError, setFormError] = useState<any>(null);

  const { mutate, isLoading } = useMutation(
    client.users.verifyForgotPasswordToken,
    {
      onSuccess: (data, variables) => {
        if (!data.success) {
          setFormError({
            token: data?.message ?? "",
          });
          return;
        }
        actions.updateFormState({
          step: "Password",
          token: variables.token as string,
        });
      },
      onSettled: () => {
        queryClient.clear();
      },
    }
  );

  return { mutate, isLoading, formError, setFormError };
}
