import { FC, useEffect, useState } from "react";
import "./layout-style2.css";
import {
  BusinessProfileDetails,
  BusinessProfileResponseModel,
  InPersonService,
  SellerOverallScore,
  SellerOverallScoreResponse,
} from "types/business-profile-models";
import { useModalAction } from "provider/modal.context";
import Currency from "utils/constants/currency.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { CommunicationSendMessageModel } from "types/communicationMessageModels";
import { Form } from "components/ui/forms/form";
import Input from "components/ui/forms/input";
import { useTranslation } from "react-i18next";
import TextArea from "components/ui/forms/text-area";
import Button from "components/ui/button";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSellerOverallScore, useSendMessage } from "service/hook/customer";
import { MainResponse } from "types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Routes as routesPath } from "config/routes";
import BusinessBranches from "../components/BusinessBranches";
import { Rating } from "@mui/material";
import BusinessReviewsView from "../components/BusinessReviews";
import ServiceBook from "../components/ServiceBook";

interface IProps {
  businessProfileData: BusinessProfileResponseModel;
}

const communicationSendMessageSchema = yup.object().shape({
  title: yup.string().required("form:error-input-label-product-title-required"),
  customer_email: yup
    .string()
    .email("form:error-email-format")
    .required("form:error-input-customer-email"),
  message_content: yup.string().required("form:error-input-message-content"),
});

export const businessKey = "business-url";
const CustomerBusinessLayout2: FC<IProps> = ({ businessProfileData }) => {
  const [scoreData, setRateData] = useState<SellerOverallScore>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModalAction();
  const [state, setState] = useState<any>({
    branches: false,
    reviews: false,
    serviceBook: false,
  });
  const [serviceDetails, setServiceDetails] =
    useState<BusinessProfileDetails>();

  const showModalDetails = (service: InPersonService) => {
    openModal("BusinessProfileServiceDetails", {
      service,
      businessDetailId: `${businessProfileData.businessdetail_id}`,
      callback: (businessServiceDetails: BusinessProfileDetails) => {
        setServiceDetails(businessServiceDetails);
        setState({
          serviceBook: true,
        });
      },
    });
  };
  const { mutate: mutateSendMessage, isLoading } = useSendMessage();
  const { mutate: mutateSellerOverallScore } = useSellerOverallScore();

  const { reset } = useForm<CommunicationSendMessageModel>();

  const onSubmit = (data: CommunicationSendMessageModel) => {
    mutateSendMessage(
      {
        businessdetail_id: `${businessProfileData.businessdetail_id}`,
        sender_type: "customer",
        title: data.title,
        customer_email: data.customer_email,
        message_content: data.message_content,
      },
      {
        onSuccess: (successData: MainResponse) => {
          if (successData.final_result_code === "000") {
            toast.success(successData.general_result);
            reset({
              businessdetail_id: "",
              message_content: "",
              sender_type: "",
              title: "",
              customer_email: "",
            });
          }
        },
      }
    );
  };

  const getSellerOverallScore = () => {
    mutateSellerOverallScore(
      {
        businessdetail_id: `${businessProfileData.businessdetail_id}`,
        marketplaceId: "GBR",
      },
      {
        onSuccess: (successData: SellerOverallScoreResponse) => {
          setRateData(successData.captured_data);
        },
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem(businessKey);
    getSellerOverallScore();
  }, [businessProfileData]);

  return (
    <div className="business-layout seller-layout-2 my-10">
      <header>
        <div className="header-container">
          <div className="logo cursor-pointer" onClick={() => setState({})}>
            <img
              src={businessProfileData.business_logo.file_urls?.[0]?.url}
              alt="Business Logo"
            />
            <span>{businessProfileData.businessdetail_name}</span>
          </div>
          <nav>
            <ul>
              <li>
                <a onClick={() => setState({})}>Home</a>
              </li>
              <li>
                <a href="#about" onClick={() => setState({})}>
                  About
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setState({
                      branches: true,
                    });
                  }}
                >
                  Branches
                </a>
              </li>
              <li
                onClick={() => {
                  setState({
                    reviews: true,
                  });
                }}
              >
                <a>Reviews</a>
              </li>
              <li>
                <a href="#experience" onClick={() => setState({})}>
                  Experience
                </a>
              </li>
              <li>
                <a href="#certificates" onClick={() => setState({})}>
                  Certificates
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setState({})}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div className="flex justify-center items-center bg-gray-50 mt-[5%]">
        <div className="w-[800px] h-[550px]">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper w-full h-full"
          >
            {businessProfileData.business_profile.story_sliders_urls.map(
              (image, index) => (
                <SwiperSlide key={index}>
                  <img
                    width={200}
                    height={800}
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full h-full object-fill"
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </div>
      {state.branches ? (
        <BusinessBranches
          businessDetailId={businessProfileData.businessdetail_id}
        />
      ) : state.reviews ? (
        <BusinessReviewsView businessProfileData={businessProfileData} />
      ) : state.serviceBook ? (
        <ServiceBook
          businessProfileData={businessProfileData}
          serviceDetails={serviceDetails}
          showMainPage={() => setState({})}
        />
      ) : (
        <>
          <section id="about" className="relative">
            <div className="text-left absolute">
              <div className="flex gap-2">
                <Rating
                  name="simple-controlled"
                  value={scoreData?.seller_score_average ?? 0}
                  readOnly
                  precision={0.25}
                />
                <div
                  className="cursor-pointer hover:text-blue-500"
                  onClick={() =>
                    setState({
                      reviews: true,
                    })
                  }
                >{`(${scoreData?.number_of_scores ?? 0})`}</div>
              </div>
            </div>
            <h2 className="text-center">About Us</h2>

            <p className="text-left">
              {businessProfileData.business_profile.about_business}
            </p>
          </section>

          <section id="experience">
            <h2>Our Experience</h2>
            <p className="text-left">
              {businessProfileData.business_profile.experience_of_business}
            </p>
          </section>

          <section id="services">
            <h2>Our Services</h2>
            <div
              className="w-full"
              style={{
                display: "ruby",
              }}
            >
              {businessProfileData.in_person_services.map((service) => (
                <div
                  className="service flex flex-col gap-4 justify-between h-56 mb-4 mx-2"
                  style={{
                    width: "31%",
                  }}
                >
                  <h3>{service.service_title}</h3>
                  <h4>{`${service.price.toLocaleString()} ${
                    (Currency.currencyObj as any)[service.currency]
                  }`}</h4>
                  <div className="flex gap-4 w-full">
                    <Button
                      type="button"
                      className="bg-accent-500 hover:bg-accent-hover text-white"
                      onClick={() => showModalDetails(service)}
                    >
                      {t("common:text-details")}
                    </Button>
                    <Button
                      className="text-white bg-cyan-500 hover:bg-cyan-600"
                      onClick={() => {
                        localStorage.setItem(
                          businessKey,
                          `${window.location.origin}${window.location.pathname}`
                        );
                        navigate(routesPath.payment, {
                          state: {
                            currency: service.currency,
                            price: service.price,
                            businessDetailId: `${businessProfileData.businessdetail_id}`,
                          },
                        });
                      }}
                    >
                      {t("common:text-pay")}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section id="team">
            <h2>Our Teams</h2>
            <div className="team-grid">
              {businessProfileData.business_profile.management_team.map(
                (team) => (
                  <div className="team-member w-44 flex flex-col justify-between items-center">
                    <img src={team.photo} className="w-24 h-24" />
                    <h3 className="font-bold">{team.full_name}</h3>
                    <p>{team.role}</p>
                  </div>
                )
              )}
            </div>
          </section>

          <section id="team">
            <h2>Our Partners</h2>
            <div className="team-grid">
              {businessProfileData.business_profile.partners.map((team) => (
                <div className="team-member">
                  <h3>{team.partner_name}</h3>
                  <p>{team.details}</p>
                </div>
              ))}
            </div>
          </section>
          <section id="certificates">
            <h2>Our Certifications</h2>
            <div className="certificates-grid">
              {businessProfileData.business_profile.licence_or_certificate.map(
                (certificate, index) => (
                  <div
                    className="certificate-item w-1/3 h-56 flex flex-col justify-between items-center"
                    key={index}
                  >
                    <img
                      src={certificate.url}
                      alt={`Certificate-${index}`}
                      className="w-24 h-24"
                    />
                    <p>
                      {certificate.licence_name}{" "}
                      {certificate.date_of_achievement}
                    </p>
                  </div>
                )
              )}
            </div>
          </section>

          <section id="contact" className="w-3/5">
            <h2>Contact Us</h2>
            <Form<CommunicationSendMessageModel>
              onSubmit={onSubmit}
              validationSchema={communicationSendMessageSchema}
              serverError={null}
            >
              {({ register, formState: { errors } }) => (
                <>
                  <Input
                    label={t("common:text-customer-email")}
                    {...register("customer_email")}
                    variant="outline"
                    className="mb-5"
                    error={t(errors.customer_email?.message!)}
                  />
                  <Input
                    label={t("common:text-title")}
                    {...register("title")}
                    variant="outline"
                    className="mb-5"
                    error={t(errors.title?.message!)}
                    maxLength={50}
                  />
                  <TextArea
                    label={t("common:text-message-content")}
                    {...register("message_content")}
                    variant="outline"
                    className="my-6"
                    error={t(errors.message_content?.message!)}
                    maxLength={500}
                  />
                  <div className="mt-8">
                    <Button
                      className="h-12 w-full"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      {t("common:text-send")}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </section>
        </>
      )}
      <footer className="fixed bottom-0 w-full">
        <p>&copy; 2023 Milborak. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default CustomerBusinessLayout2;
