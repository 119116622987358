import Loader from "components/ui/loader/loader";
import Table from "rc-table";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessBranches } from "service/hook/customer";
import {
  BranchModel,
  BusinessBranchLocationResponse,
  BusinessProfileServicesRequestModel,
} from "types/business-profile-models";

interface IProps {
  businessDetailId: number;
}

const BusinessBranches: FC<IProps> = ({ businessDetailId }) => {
  const { t } = useTranslation("common");

  const [branchesList, setBranchesList] = useState<BranchModel[]>([]);

  const { mutate: mutateBusinessBranches, isLoading } = useBusinessBranches();

  const MessagesTableColumns = useMemo(
    () => [
      {
        title: t("text-branch-address"),
        dataIndex: "addressLine1",
        key: "addressLine1",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 200,
      },
      {
        title: t("text-city"),
        dataIndex: "city",
        key: "city",
        align: "left",
        ellipsis: true,
        className: "!text-sm",
        width: 110,
      },
      {
        title: t("text-phone"),
        dataIndex: "phone",
        key: "phone",
        align: "center",
        className: "!text-sm",
        width: 80,
      },
      {
        title: t("text-postal-code"),
        dataIndex: "country",
        key: "country",
        align: "center",
        ellipsis: true,
        className: "!text-sm",
        width: 150,
      },
      {
        title: t("text-location"),
        dataIndex: "location_name",
        key: "location_name",
        align: "left",
        className: "!text-sm",
        width: 150,
      },
      {
        title: t("state-or-province"),
        dataIndex: "stateOrProvince",
        key: "stateOrProvince",
        align: "left",
        className: "!text-sm",
        width: 150,
      },
    ],
    [t]
  );

  const getBranches = () => {
    const input: Pick<
      BusinessProfileServicesRequestModel,
      "businessdetail_id"
    > = {
      businessdetail_id: `${businessDetailId}`,
    };
    mutateBusinessBranches(input, {
      onSuccess: (successData: BusinessBranchLocationResponse) => {
        if (successData.final_result_code === "000") {
          setBranchesList(successData.captured_data);
        }
      },
    });
  };

  useEffect(() => {
    getBranches();
  }, []);

  return (
    <>
      <div className="mx-auto flex w-full max-w-7xl flex-col px-5 pb-20 md:flex-row md:pb-10 xl:px-8 xl:pb-14 2xl:px-14">
        <div className="flex h-full flex-col w-full">
          <div className="flex items-center justify-start mb-8 sm:mb-10 mt-5 px-5">
            <div className="flex flex-col">
              <h3 className="text-lg font-semibold text-heading mb-4">
                {`${t("text-branches")}`}
              </h3>
            </div>
          </div>
          {isLoading ? (
            <Loader
              className="!h-full flex-1"
              text={t("common:text-loading")}
            />
          ) : (
            <>
              <div className="mb-6 overflow-hidden rounded shadow overflow-x-auto">
                <Table
                  /* @ts-ignore */
                  columns={MessagesTableColumns}
                  emptyText={t("table:empty-table-data")}
                  data={branchesList}
                  rowKey="location_id"
                  scroll={{ x: 900 }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BusinessBranches;
